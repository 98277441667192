






import {Component, Vue} from "vue-property-decorator";
import ControlPanelMenu from "../components/controlpanel/ControlPanelMenu.vue";

@Component({
  components: {
    ControlPanelMenu,
  },
})
export default class ControlPanel extends Vue {
}
